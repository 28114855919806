import React from 'react';
import ChatInterface from './components/ChatInterface';

function App() {
  return (
	  <>
		<ChatInterface />
	  </>
  );
}

export default App;
